import { defineNuxtRouteMiddleware } from '#app'
import { BASE_METHOD, fetchApi } from '~/common/API/baseApi'
import PlaceService from '~/services/PlaceService'

export default defineNuxtRouteMiddleware(async (to) => {
  const path = to.path.split('/')[1] // Получаем первый сегмент пути
  
  // Проверяем существование страницы
  const pages = ['adv', 'advertiser', 'group', 'news', 'balance', 'creatives', 'deposit', 'login']
  
  if (pages.includes(path)) {
    return
  }
  
  // Если страница не существует, проверяем наличие ID
  const id = to.params.id
  if (id) {
    try {
      const response = await PlaceService.getPlace(id as string)
      console.log("response", response);
      return
    } catch (error) {
      console.log("error", error);
      throw createError({
        statusCode: 404,
        message: 'Страница не найдена'
      })
    }
  }
  
  // Если ни страница, ни ID не найдены, выбрасываем ошибку
  throw createError({
    statusCode: 404,
    message: 'Страница не найдена'
  })
}) 